<template lang="pug">
  .topbar(ref="main",:class="alertMode ? 'alert-mode' : ''")
    .panel-back
      include ../../../app/assets/gamepanel-no-numbers.svg
    .panel-overlay
      .panel-overlay-inner
        .timer {{ timer }}
        .score {{ score }}
        .count {{ count }}
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['timer', 'score', 'count'],

  data() {
    return {
      alertMode: false,
    }
  },

  watch: {
    timer (val, oldVal) {
      //console.log(val);
      if (val < 20 && !this.alertMode) {
        this.alertMode = true;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
  @import '~/app/styles/comps/_componentbase.scss';
  @import '~/app/styles/comps/_game-status-bar.scss';
</style>
