<template lang="pug">
    .main-container
      component(v-bind:is="currentGame",v-bind:cfg="currentGameCfg",v-if="ready")
</template>

<script lang="">
import Vue from "vue";

export default Vue.extend({
  mounted () {
    this.route();
    window.addEventListener('hashchange', this.route, false);
  },

  data() {
    return {
      ready: false,
      currentGame: '',
      currentGameCfg: {},
    };
  },

  components: {
      basic: () => import('./Basic'),
      quiz: () => import('./Quiz'),
      flip: () => import('./Flip'),
      slideshow: () => import('./Slideshow'),
      list: () => import('./List'),
  },

  methods: {
    // setup fetching data and content based on fragment routing
    route () {
      console.log('routing 0.1.3', this.getGameId());
      if (this.getGameId() == 'list') {
        console.log('render list');
        this.currentGameCfg = {};
        this.bootstrapGame ({
          config: {
            type: 'list',
          }
        });
        return;
      }
      if ( this.getGameId() !== "" && this.getLang() !== "" ) {
        this.getCmsGlobals();
      }
    },

    getCmsGlobals () {
      fetch(`./cms/storage/pack/${this.getLang()}/globals.json`)
        .then( response => {
          return response.json();
        })
        .then(json => {
          //this.currentGameCfg.global = json;
          this.getGameSetupCms(json);
        }, error => {
          throw new Error(`GetGlobalLang error: could not get json or json malformed: ${error}`);
        });
    },

    getGameSetupCms (cmsGlobals) {
      fetch(`./cms/storage/pack/${this.getLang()}/${this.getGameId()}.json`)
        .then( response => {
          return response.json();
        })
        .then(json => {
          // setup config
          this.currentGameCfg = Object.create(json._cfg);
          this.currentGameCfg.config.type = this.currentGameCfg.config.type.toLowerCase();
          this.currentGameCfg.id = this.getGameId();
          this.currentGameCfg.lang = this.getLang();
          this.currentGameCfg.global = { strings: cmsGlobals };
          this.currentGameCfg.content = json;
          this.bootstrapGame(this.currentGameCfg);
        });
    },

     // initializes game component with content
    bootstrapGame (data) {
      console.log('bootstrap game:', data);
      this.currentGame = data.config.type;
      this.ready = true;
    },

    getGameId() {
      let hash = window.location.hash.split('/');
      if (hash.length < 2 ) throw new ErrorEvent('path is malformed');
      return hash[1];
    },

    getLang() {
      let hash = window.location.hash.split('/');
      if (hash.length < 3 ) throw new ErrorEvent('path is malformed');
      return hash[2];
    },
  }
});
</script>
