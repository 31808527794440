<template lang="pug">
  .game-title-screen
    .content
      h1(class="main-title") {{ str.title }}
      p(class="intro") {{ str.instructions }}
      .img-container(v-if="str.img")
        img(v-if="str.img",:src="str.img")
      div(v-if="str.extra",v-html="str.extra",class="html-content")
      p {{ cfg.global.strings.timeMessage.replace('%TIME%', cfg.config.time) }}
    .cta
      .rocket
      b-button(v-on:click="$emit('click-start')",class="btn-start") {{ startButtonLabel }}
</template>

<script lang="">
import Vue from "vue";
import Element from './Element';
//import Helpers from "../common/Helpers";

export default Vue.extend({
  name: 'game-title-screen',
  props: ['cfg'],

  components: {
    Element
  },

  created () {
    console.log('game title screen page created', this.cfg);

  },

  mounted () {
  },

  data () {
    return {
      str: this.cfg.content.startScreen,
    }
  },

  computed: {
    startButtonLabel: function () {
      return this.str.startButtonLabel || this.cfg.global.strings.startButtonLabel || 'StartButtonLabel';
    }
  },

  watch : {
    //cfg : (val, oldVal) => {
    //  console.log(val, val.config);
    //}
  },

  methods : {
  }
});
</script>
<style lang="scss" scoped>
@import '~/app/styles/comps/_componentbase.scss';
//@import '~/app/styles/comps/_quiz.scss';
.game-title-screen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
  }
}
</style>
