<template lang="pug">
  .game-end-screen
    .plate
      .top-plate
        include ../../assets/plate-top.svg

      .mid-plate
        .inner
          .trophy.icon(v-if="isWin",ref="trophy")
            include ../../assets/trophy.svg
          .sad-face.icon(v-else)
          h1.title {{ endMessage.title }}
          p.score {{ this.cfg.global.strings.yourScore + ' ' + score }}

      .bottom-plate
        include ../../assets/plate-bottom.svg

    .img-container
      img(v-if="str.img",:src="str.img")

    p(class="endmsg") {{ endMessage.message }}
    p(class="endmsg",v-if="isWin") {{ endMessageReport }}

    div(v-if="str.extra",v-html="str.extra",class="html-content")
    br
    b-button(v-on:click="$emit('click-start')",v-if="!isWin") {{ startButtonLabel }}
</template>

<script lang="">
import Vue from "vue";
import Element from './Element';
import {readFileSync} from 'fs';
import Content from '../mixins/Content';
//import Helpers from "../common/Helpers";

export default Vue.extend({
  name: 'game-end-screen',
  props: {
    cfg: {type: Object, required: true},
    isWin: {type: Boolean, required: false},
    score: {type: String, required: false}
  },

  components: {
    Element
  },

  created () {
    console.log('game end screen page created', this.cfg);
  },

  mounted () {
    if (this.isWin) {
      let els = this.$refs.trophy.querySelectorAll('path[id^="star-copy"]');
      els.forEach(el => {
        el.style.animationDuration = ( (Math.random()*4) + 1 ) + 's';
      });
    }
    //console.log('game end screen page mounted', this.$refs.trophy, els);
  },

  data () {
    return {
      str: this.cfg.content.endScreen || this.cfg.global.strings.endScreen
    }
  },

  computed: {
    startButtonLabel () {
      return this.str.retryButtonLabel || this.cfg.global.strings.retryButtonLabel || 'RetryButtonLabel';
    },

    successMsg () {
      return this.str.success;
    },

    failMsg () {
      return this.str.fail;
    },

    endMessage () {
      return (this.isWin) ? this.successMsg : this.failMsg;
    },
  },

  watch : {
    //cfg : (val, oldVal) => {
    //  console.log(val, val.config);
    //}
  },

  methods : {
  },

  mixins: [
    Content
  ]
});
</script>
<style lang="scss" scoped>
@import '~/app/styles/comps/_componentbase.scss';
@import '~/app/styles/comps/_game-end-screen.scss';
</style>
