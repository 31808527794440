export default {
  created () {
    console.log('GameTimer::created()');
  },

  data () {
    return {
      timer: null,
      totalTime: 0,
      timerPaused: false,
    }
  },

  methods: {
    startTimer (totalTime, tick, callback) {
      if (this.timer) {
        throw new Error('GameTimer::startTimer called without before ending timer');
        return;
      }
      this.totalTime = totalTime;
      this.timer = setInterval(() => {
        if (this.timerPaused) return;
        //console.log('tick', this.totalTime);
        this.totalTime--;
        if (this.totalTime < 0) {
          callback();
        }
      }, tick, this.totalTime);
      console.log('GameTimer started', this.totalTime, 'secs');
    },

    pauseTimer () {
      this.timerPaused = true;
    },

    resumeTimer () {
      this.timerPaused = false;
    },

    endTimer () {
      clearInterval(this.timer);
      this.timer = null;
      console.log('timer clear', this.timer);
    },
  }
}
