<template lang="pug">
  component(
      :is="rtag",
      :style="(ratts) ? ratts.style : null"
      :class="(ratts) ? ratts.class : null"
      :src="(ratts) ? `/${path}/${ratts.src}` : null"
      :path="path"
    ) {{ rtext }}
    elm(
      v-for="child in rchildren",
      :tag="child.tag",
      :text="child.text",
      :children="child.children",
      :style="(child.atts) ? child.atts.style : null"
      :class="(child.atts) ? child.atts.class : null"
      :src="(child.atts) ? `/${path}/${child.atts.src}` : null"
      :path="path"
    )
</template>

<script lang="">
import Vue from "vue";

/**
This component expects a json representation of the DOM to render as HTML
You can populate it via the prop 'content' with a json or individual attribute props.
The stucture is as follows:
{
  // a single root node is required
  tag: 'div', // the HTML tag
  text: 'An example here', // the text in this node
  atts: {
    style: '', // HTML style attribute
    class: '', // HTML class attribute
    src: '', // HTML src attribute (only for img tags)
  },
  children: [ // An array of nodes following the same structure
    {
      tag: 'p',
      text: 'example',
    },
    {
      tag: 'h1',
      text: 'Another example',
    },
  ]
}
*/

export default Vue.extend({
  name: 'elm',
  props:{
    content: {type:Object, required:false},
    tag:{type:String, required:false},
    text:{type:String, required:false},
  	children:{type:Array, required:false},
    atts:{type:Object, required:false},
    path:{type:String, required:false},
  },
  data : function () {
    return {
      //content: {},
      //tag: this.content.tag,
    }
  },
  computed: {
    // resolve for root element
    rtag: function () { return (this.content) ? this.content.tag : this.tag },
    rtext: function () { return (this.content) ? this.content.text : this.text },
    rchildren: function () { return (this.content) ? this.content.children : this.children },
    ratts: function () { return (this.content) ? this.content.atts : this.atts },
    rsrc: function () { return (this.content) ? this.content.atts : this.atts },
  }
});
</script>
