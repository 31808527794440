export default {

  computed: {
    endMessageReport () {
      if ( this.cfg.config.hasOwnProperty('irlTask') && this.cfg.config.irlTask ) {
        return this.cfg.global.strings.endScreen_reportMsgIRL;
      }
      return this.cfg.global.strings.endScreen_reportMsg;
    }
  }

}
