<template lang="pug">
  Main
</template>

<script lang="">
import Vue from "vue";
import Main from "./app/components/main.vue";

export default {
  name: 'App',
  created () {
    console.log('app created');
  },
  components: {
      Main,
  }
};
</script>
